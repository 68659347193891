// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import '~@fortawesome/fontawesome-free/css/all.css';
// OverlayScrollbars
@import '~overlayscrollbars/css/OverlayScrollbars.css';
// Import stylesheet
@import '~vue-loading-overlay/dist/vue-loading.css';
// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';
// AdminLTE
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';

// Variables
@import 'variables';

// toastr
@import "~toastr/toastr.scss";

// Bootstrap
// Already imported by AdminLTE
//@import '~bootstrap/scss/bootstrap';

.bg-error {
    background-color: $error;
}

a {
    cursor: pointer;
}

div[aria-expanded=false] .vs__selected-options .vs__search:not(:focus):not(.vs--open):nth-child(2){
    display: none;
}